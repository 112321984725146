import { Button, Row } from "react-bootstrap";
import Modal from "./Modal";

export default function ConfirmationModal({ hideOK, hideCancel, title, show, message, onResponse }) {
	const footer =  <Row>
						{
							!hideOK &&
							<Button onClick={ e => onResponse(true) }>OK</Button>
						}
						{
							!hideCancel &&
							<Button variant="danger" onClick={ e => onResponse(false) } className="ml-2">Cancel</Button>
						}
					</Row>

	return <Modal show={ show } title={ title } footer={ footer } onHide={ e => onResponse(false)}>
		<h6>{message}</h6>
	</Modal>
}