import { Button, Row } from "react-bootstrap"
import Modal from "../../../Core/Modal"
import { useState } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function ConfirmPWADeleteModal({ show, member, onClose }) {
	const store = useSessionStore();

	const [deleting, setDeleting] = useState(false);

	async function handleResponse(result) {
		if(result) {
			setDeleting(true);
			let result = await store.MembersStore.MembersService.DeleteMemberPWARegistration(member.Id);
			setDeleting(false);

			if(result && result.Success) {
				store.ToastStore.ShowSuccessToast("Member's PWA Registration Successfully Deleted.", "PWA Registration Deleted!");
				store.MembersStore.GetMembers();
				onClose();
			} else {
				store.ToastStore.ShowErrorToast("An error occurred attempting to delete the member's PWA registration. Please contact support.", "Error");
			}
		} else {
			onClose();
		}
	}

	const footer =  <Row>
						<Button disabled={ deleting } onClick={ () => handleResponse(true) }>{ deleting ? "OK..." : "OK" }</Button>
						<Button disabled={ deleting } variant="danger" onClick={ () => handleResponse(false) } className="ml-2">Cancel</Button>
					</Row>

	return 	<Modal show={ show } title="Delete PWA Registration" footer={ footer } onHide={ () => onClose()}>
				<h6>Are you sure you want to delete this member's PWA registration?</h6>
			</Modal>
}