import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SortTableHeader({ onClick, lable, sortBy, storeSortBy, storeSortByDescending }) {
    return (
        <th
            style={{ cursor: "pointer" }}
            onClick={ e => {
                onClick(e);
            }}
        >
            <span className="d-flex flex-row justify-content-between">
                { lable }
                <div className="flex-column d-flex">
                    <FontAwesomeIcon className="align-self-end" color={!storeSortByDescending && storeSortBy === sortBy ? "##212529" : "#DEE2E6"} size={"xs"} icon={ faChevronUp } />
                    <FontAwesomeIcon className="align-self-end" color={storeSortByDescending  && storeSortBy === sortBy ? "##212529" : "#DEE2E6"} size={"xs"} icon={ faChevronDown } />
                </div>
            </span>
        </th>
    );
}