import { Col, Row, Form, Button } from "react-bootstrap";
import { MemberUserNotificationTypes, getMemberUserNotificationType } from "./Enums";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from "react";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function CreateNotification({ onClose }) {
	const store = useSessionStore();

	const [type, setType] = useState("0");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [filter, setFilter] = useState(0);

	const [error, setError] = useState("");
	const [busy, setBusy] = useState(false);

	async function createNotification() {
		if(!subject) {
			setError("Subject is required.");
			return;
		}

		if(!message) {
			setError("Message is required.");
			return;
		}

		setBusy(true);

		const result = await store.NotificationsService.CreateNotification({
			NotificationType: parseInt(type),
			Subject: subject,
			Body: message,
			Filter: filter
		});

		if(result && result.Success) {
			store.ToastStore.ShowSuccessToast("Notification created successfully.", "Notification created.");
			onClose(true);
		} else {
			store.ToastStore.ShowErrorToast("An error occurred attempting to create the notification.", "Error creating notification.");
		}
	}

	return 	<Row>
				<Col>
					<Form.Group>
						<Form.Label>Notification Type</Form.Label>
						<select className="form-control" value={ type } onChange={ e => setType(e.target.value) }>
							<option value={ MemberUserNotificationTypes.All }>{ getMemberUserNotificationType(MemberUserNotificationTypes.All) }</option>
							<option value={ MemberUserNotificationTypes.PensionersWithNoAddress }>
								{ getMemberUserNotificationType(MemberUserNotificationTypes.PensionersWithNoAddress) }
							</option>
							<option value={ MemberUserNotificationTypes.FICANonCompliant }>
								{ getMemberUserNotificationType(MemberUserNotificationTypes.FICANonCompliant) }
							</option>
							<option value={ MemberUserNotificationTypes.BalanceMoreThanSpecifiedNotTerminated }>
								{ getMemberUserNotificationType(MemberUserNotificationTypes.BalanceMoreThanSpecifiedNotTerminated) }
							</option>
						</select>
					</Form.Group>
					{
						parseInt(type) === MemberUserNotificationTypes.BalanceMoreThanSpecifiedNotTerminated &&
						<Form.Group>
							<Form.Label>Balance Amount</Form.Label>
							<Form.Control type="number" value={filter} onChange={ e => setFilter(e.target.value) }></Form.Control>
						</Form.Group>
					}
					<Form.Group>
						<Form.Label>Subject</Form.Label>
						<Form.Control value={subject} onChange={ e => setSubject(e.target.value) }></Form.Control>
					</Form.Group>
					<Form.Group>
						<Form.Label>Message</Form.Label>
						<ReactQuill theme="snow" value={message} onChange={setMessage} />
					</Form.Group>
					<Row className="modal-footer pb-0">
					{
						error &&
						<div className="text-danger">{error}</div>
					}
					<Button disabled={ busy } onClick={ createNotification } type="button">{ busy ? "Creating..." : "Create" }</Button>
					<Button onClick={ () => onClose(false) } className="ml-2" variant="danger" disabled={ busy } type="button">Cancel</Button>
				</Row>
				</Col>
			</Row>
}