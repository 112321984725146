import { useSessionStore } from "../../../../Stores/SessionStore";
import ClickIcon from "../../../Core/ClickIcon";
import ReinstateMember from "./ReinstateMember";
import { MemberStatuses } from "../../../../Stores/Members/MemberStore";
import { faBookDead, faEllipsisV, faEraser, faMoneyBillWaveAlt, faTrash, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import TerminateMemberModal from "../TerminateMemberModal";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import * as Bootstrap from "bootstrap"
import CreateCashDepositModal from "../Transactions/CreateCashDepositModal";
import CreateWriteOffModal from "../Transactions/CreateWriteOffModal";
import DeathBenefitPayoutModal from "../DeathBenefitPayoutModal";
import ConfirmPWADeleteModal from "./ConfirmPWADeleteModal";

export default function MoreActionsDropDown({ member, onReinstating }) {
	const [terminateModalShown, showTerminateModal] = useState(false);
	const [createDepositModalShown, showCreateDepositModal] = useState(false);
	const [createWriteOffModalShown, showCreateWriteOffModal] = useState(false);
	const [createDeathBenefitPayoutModalShown, showCreateDeathBenefitPayoutModal] = useState(false);
	const [confirmDeletePWARegModalShown, showConfirmPWARegDeleteModal] = useState(false);

	const store = useSessionStore();

	function handleTerminateMemberComplete(success) {
		if(success) {
			store.MembersStore.CRUD.GetEntries();
		}

		showTerminateModal(false);
	}

	useEffect(() => {
		const drop = $(`#${member.Id}`);
		const ddn = new Bootstrap.Dropdown(drop);

		function handleClick(e) {
			if(e.path && (e.path[0] || e.path[1]) && (e.path[0].id !== member.Id && e.path[1].id !== member.Id)) {
				ddn.hide();
			}

			if(e.target && (e.target.id !== member.Id && e.target.parentNode && e.target.parentNode.id !== member.Id)) {
				ddn.hide();
			}
		}

		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		}

	}, []);

	return 	<span>
				<div className="dropdown" >
					<FontAwesomeIcon fixedWidth title="More Actions..." id={ member.Id } data-toggle="dropdown" style={{ cursor: "pointer" }} className="dropdown-toggle text-primary" icon={ faEllipsisV }></FontAwesomeIcon>
					<div className="dropdown-menu">
						{
							member.MemberStatusId !== MemberStatuses.Terminated &&
							<button className="dropdown-item" onClick={ e => showTerminateModal(true) }>
								<span style={{ width: "22px", display: "inline-block" }}>
									<ClickIcon title="Terminate Member"  icon={ faUserSlash }></ClickIcon>
								</span>
								<span className="ml-2"> Terminate Member</span>
							</button>
						}
						{
							member.MemberStatusId === MemberStatuses.Terminated &&
							<button className="dropdown-item">
								<ReinstateMember showText memberId={member.Id} onReinstating={ onReinstating } onMemberReinstated={ e => store.MembersStore.CRUD.GetEntries() }></ReinstateMember>
							</button>
						}
						<button className="dropdown-item" onClick={ e => showCreateDepositModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Cash Deposit" icon={ faMoneyBillWaveAlt }></ClickIcon>
							</span>
							<span className="ml-2"> Create Cash Deposit</span>
						</button>
						<button className="dropdown-item" onClick={ e => showCreateWriteOffModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Write Off" icon={ faEraser }></ClickIcon>
							</span>
							<span className="ml-2"> Create Write Off</span>
						</button>
						<button className="dropdown-item" onClick={ e => showCreateDeathBenefitPayoutModal(true) }>
							<span style={{ width: "22px", display: "inline-block" }}>
								<ClickIcon title="Create Write Off" icon={ faBookDead }></ClickIcon>
							</span>
							<span className="ml-2"> Death Benefit Payout</span>
						</button>
						{
							member.RegisteredOnPWA &&
							<button className="dropdown-item" onClick={ e => showConfirmPWARegDeleteModal(true) }>
								<span style={{ width: "22px", display: "inline-block" }}>
									<ClickIcon title="Delete PWA Registration" icon={ faTrash }></ClickIcon>
								</span>
								<span className="ml-2"> Delete PWA Registration</span>
							</button>
						}
					</div>
				</div>

        		<TerminateMemberModal member={ member } memberId={ member.Id } show={ terminateModalShown } onClose={ handleTerminateMemberComplete }></TerminateMemberModal>
				<CreateCashDepositModal member={ member } show={ createDepositModalShown } onClose={ e => showCreateDepositModal(false) }></CreateCashDepositModal>
				<CreateWriteOffModal member={ member } show={ createWriteOffModalShown } onClose={ e => showCreateWriteOffModal(false) }></CreateWriteOffModal>
				<DeathBenefitPayoutModal member={member} show={ createDeathBenefitPayoutModalShown } onClose={ e => showCreateDeathBenefitPayoutModal(false) }></DeathBenefitPayoutModal>
				<ConfirmPWADeleteModal 
					member={ member }
					show={ confirmDeletePWARegModalShown }
					onClose={ () => showConfirmPWARegDeleteModal(false) }
				/>
			</span>
}