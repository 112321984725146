import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import UsersService from "../Services/UsersService";
import DepartmentsService from "../Services/DepartmentsService";
import MembersService from "../Services/MembersService";
import ControlsService from "../Services/ControlsService";
import DataChangeApprovalsService from "../Services/DataChangeApprovalsService";
import SettingsService from "../Services/SettingsService";
import MemberWithdrawalsService from "../Services/MemberWithdrawalsService";
import MemberBalancesService from "../Services/MemberBalancesService"
import TransactionsService from "../Services/TransactionsService"
import BatchesService from "../Services/BatchesService";
import DepositsImportsService from "../Services/DepositsImportsService";
import CATSTransfersService from "../Services/CATSTransfersService";
import PeriodEndService from "../Services/PeriodEndService";
import HistoriesService from "../Services/HistoriesService";
import AdhocReportsService from "../Services/AdhocReportsService"
import NotificationsService from "../Services/NotificationsService"

import UserStore from "./UserStore";
import DepartmentStore from "./DepartmentStore";
import MemberStore from "./Members/MemberStore";
import ControlStore from "./ControlStore";
import ApprovalsStore from "./ApprovalsStore";
import SettingsStore from "./SettingsStore";
import ToastStore from "./ToastStore";
import MemberWithdrawalsStore from "./Members/MemberWithdrawalsStore";
import MemberBalancesStore from "./Members/MemberBalancesStore"
import TransactionsStore from "./TransactionsStore";
import BatchesStore from "./BatchesStore";
import AdhocReportsStore from "./AdhocReportsStore";

/**
 * @typedef Session
 * @type {object}
 * @property {string} BearerToken - The JWT Token used to authenticate the client.
 * @property { import('../Services/UsersService').User } User - The logged in user
 */

/**
 * @typedef Config
 * @type {object}
 * @property {string} apiUrl - The URL to the application's backend API.
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

/**
 * @typedef SessionStore
 * @type {SessionStore}
*/
export class SessionStore {

    /**@type {Config} */ Config = null;
    /**@type {Session} */ Session = null;
    /**@type {UsersService} */ UsersService = null;
    LoggingIn = false;

    /**@param {Config} config */
    constructor(config) {
        this.Config = config;

        //attempt to initialise the session from local storage
        this.GetStoredSession();

        //initialise services
        this.UsersService = new UsersService(config.apiUrl, this);
        this.DepartmentsService = new DepartmentsService(config.apiUrl, this);
		this.DepositsImportsService = new DepositsImportsService(config.apiUrl, this);
		this.CATSTransfersService = new CATSTransfersService(config.apiUrl, this);
		this.PeriodEndService = new PeriodEndService(config.apiUrl, this);
		this.HistoriesService = new HistoriesService(config.apiUrl, this);
        this.TransactionsService = new TransactionsService(config.apiUrl, this);
		this.AdhocReportsService = new AdhocReportsService(config.apiUrl, this);
        this.MemberWithdrawalsService = new MemberWithdrawalsService(config.apiUrl, this);
		this.NotificationsService = new NotificationsService(config.apiUrl, this);

        var membersService = new MembersService(config.apiUrl, this);
        var controlsService = new ControlsService(config.apiUrl, this);
        var dataChangeApprovalsService = new DataChangeApprovalsService(config.apiUrl, this);
        var settingsService = new SettingsService(config.apiUrl, this);
        var memberBalancesService = new MemberBalancesService(config.apiUrl, this);
        var batchesService = new BatchesService(config.apiUrl, this);

        //initialise stores
        this.ToastStore = new ToastStore();

        this.UsersStore = new UserStore(this.UsersService, this.ToastStore);
        this.DepartmentsStore = new DepartmentStore(this.DepartmentsService, this.ToastStore);
        this.MembersStore = new MemberStore(membersService, this.ToastStore);
        this.ControlsStore = new ControlStore(controlsService, this.ToastStore);
        this.ApprovalsStore = new ApprovalsStore(dataChangeApprovalsService, this.ToastStore);
        this.SettingsStore = new SettingsStore(settingsService, this.ToastStore);
        this.MemberWithdrawalsStore = new MemberWithdrawalsStore(this.MemberWithdrawalsService, this.ToastStore);
        this.MemberBalancesStore = new MemberBalancesStore(memberBalancesService, this.ToastStore);
        this.TransactionsStore = new TransactionsStore(this.TransactionsService, this.ToastStore);
        this.BatchesStore = new BatchesStore(batchesService, this.ToastStore);
		this.AdhocReportsStore = new AdhocReportsStore(this.AdhocReportsService, this.ToastStore);

        makeAutoObservable(this);
    }

    GetStoredSession() {
        var session = localStorage.getItem("Session");

        if(session) {
            this.Session = JSON.parse(session);
        } else {
            this.Session = null;
        }
    }

    get UserIsLoggedIn () {
        return this.Session !== null && this.Session.User !== null;
    }

    async SetLoggingIn(loggingIn) {
        runInAction(() => { this.LoggingIn = loggingIn; });
    }

    async Login(email, password) {
        this.SetLoggingIn(true);
        var result = await this.UsersService.Login(email, password);
        this.SetLoggingIn(false);

        if(result.Success) {
            localStorage.setItem("Session", JSON.stringify(result.Data));
            runInAction(() => { this.Session = result.Data; });
        }

        return result;
    }

    Logout () {
        localStorage.clear();
        this.Session = null;
    }
}

export function SessionStoreProvider(props) {
    if(SessionStoreInstance === null) {
        SessionStoreInstance = new SessionStore(props.config);
    }

    return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
    const context = useContext(SessionStoreContext);

    return context;
}