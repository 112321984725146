import { faUpload, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { DailyWithdrawalsSortColumns } from "../../../Stores/Members/MemberWithdrawalsStore";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import SortableTH from "../../Core/SortableTH";
import DailyWithdrawalActions from "./DailyWithdrawalActions";

const DailyWithdrawalsIndex = observer(function DailyWithdrawalsIndex() {
	const [withdrawals, setWithdrawals] = useState([]);
	const [showingFutureWithdrawals, showFutureWithdrawals] = useState(false);
	const [tab, setTab] = useState('current');

	const store = useSessionStore();

	async function getDailyWithdrawals() {
		await store.ControlsStore.GetActiveControl();
		var withdrawals = await store.MemberWithdrawalsStore.GetUnbatchedWithdrawals();

		if(withdrawals) {
			setWithdrawals(withdrawals);
		}
	}

	useEffect(() => {
		getDailyWithdrawals();
	}, [store.MemberWithdrawalsStore.Reference]);

	async function handlePostWithdrawalsClicked() {
		var result = await store.MemberWithdrawalsStore.PostDailyWithdrawals();

		if(result) {
			getDailyWithdrawals();
		}
	}

	function getWithdrawals() {
		if(store.ControlsStore.ActiveControl === null) {
			return [];
		}

		const processingDate = DateTime.fromISO(store.ControlsStore.ActiveControl.ProcessingDate);

		const currentWithdrawals = withdrawals.filter((item) => {
			const transactionDate = DateTime.fromISO(item.TransactionDate);
			const diff = processingDate.diff(transactionDate, 'days').toObject();

			if(showingFutureWithdrawals) {
				return diff.days < 0;
			} else {
				return diff.days >= 0;
			}
		});

		return currentWithdrawals;
	}

	const currentWithdrawals = getWithdrawals();
	const withdrawalsTotal = Math.abs(currentWithdrawals.reduce((sum, withdrawal) => sum + withdrawal.Amount, 0));

    return <Panel>
            <PanelHeader icon={ faWallet } title={ showingFutureWithdrawals ? "Pending Future Withdrawals" : "Pending Daily Withdrawals" }>
				<div className="d-flex align-items-center">
					<Form.Label className="text-nowrap mb-0 pr-2">Withdrawal source</Form.Label>
					<select 
						value={ store.MemberWithdrawalsStore.Reference } 
						disabled={ store.MemberWithdrawalsStore.PostingDailyWithdrawals } 
						className="form-control"
						onChange={ e => store.MemberWithdrawalsStore.Reference = e.target.value }
					>
						<option value="TRF">DMTF System</option>
						<option value="PWA">Member App</option>
					</select>
					<Button 
						disabled={ showingFutureWithdrawals || currentWithdrawals.length === 0 || store.MemberWithdrawalsStore.PostingDailyWithdrawals } 
						variant="outline-primary" 
						onClick={ handlePostWithdrawalsClicked }
						className="ml-3 text-nowrap"
					>
						<FontAwesomeIcon icon={ faUpload }></FontAwesomeIcon> { store.MemberWithdrawalsStore.PostingDailyWithdrawals ? "Posting Withdrawals..." : "Post All Withdrawals" }
					</Button>
				</div>
            </PanelHeader>
			<Row>
				<Col>
					<Tabs activeKey={tab} onSelect={(tab) => { setTab(tab); showFutureWithdrawals(tab === "future")}} className="border-bottom-0">
						<Tab disabled={ store.MemberWithdrawalsStore.PostingDailyWithdrawals } eventKey="current" title="Pending Daily Withdrawals"></Tab>
						<Tab disabled={ store.MemberWithdrawalsStore.PostingDailyWithdrawals } eventKey="future" title="Pending Future Withdrawals"></Tab>
					</Tabs>
				</Col>
				<Col className="d-flex align-items-center justify-content-end">
					<h5 className="mb-0 text-right">{ `Withdrawals Total: R ${withdrawalsTotal.toFixed(2)}` }</h5>
				</Col>
			</Row>
			<Row>
				<Col lg={12}>
					<Table striped bordered hover>
						<thead>
							<tr>
								<SortableTH onUpdate={ getDailyWithdrawals } column={ DailyWithdrawalsSortColumns.TransactionNumber } store={ store.MemberWithdrawalsStore }>TNO</SortableTH>
								<SortableTH onUpdate={ getDailyWithdrawals } column={ DailyWithdrawalsSortColumns.Member } store={ store.MemberWithdrawalsStore }>Member</SortableTH>
								<SortableTH onUpdate={ getDailyWithdrawals } column={ DailyWithdrawalsSortColumns.TransactionDate } store={ store.MemberWithdrawalsStore }>Transaction Date</SortableTH>
								<SortableTH onUpdate={ getDailyWithdrawals } column={ DailyWithdrawalsSortColumns.Reference } store={ store.MemberWithdrawalsStore }>Reference</SortableTH>
								<SortableTH onUpdate={ getDailyWithdrawals } className="text-right" column={ DailyWithdrawalsSortColumns.Amount } store={ store.MemberWithdrawalsStore }>Amount</SortableTH>
								<th className="text-center">Actions</th>
							</tr>
						</thead>
						{
							store.MemberWithdrawalsStore.RetrievingUnbatchedWithdrawals === false && store.ControlsStore.GettingActiveControl === false &&
							<tbody>
							{
								currentWithdrawals.length > 0 &&
								currentWithdrawals.map((withdrawal) =>
								<tr key={ withdrawal.Id }>
									<td>{ withdrawal.TransactionNumber }</td>
									<td>{ withdrawal.MemberDetails }</td>
									<td>{ DateTime.fromISO(withdrawal.TransactionDate).toFormat("yyyy-MM-dd") }</td>
									<td>{ withdrawal.Reference }</td>
									<td className="text-right">R { withdrawal.Amount.toFixed(2) }</td>
									<td className="text-center">
										<DailyWithdrawalActions transactionId={ withdrawal.Id } onWithdrawalDeleted={ getDailyWithdrawals }></DailyWithdrawalActions>
									</td>
								</tr>
								)
							}

							{
								currentWithdrawals.length === 0 &&
								<tr className="text-center">
									<td colSpan="6"><b className="text-muted">No Withdrawals Available</b></td>
								</tr>
							}
							</tbody>
						}
					</Table>
					<BusyIndicator text="Retrieving Daily Withdrawals..." show={ store.MemberWithdrawalsStore.RetrievingUnbatchedWithdrawals || store.ControlsStore.GettingActiveControl }></BusyIndicator>
				</Col>
			</Row>
		</Panel>

});

export default DailyWithdrawalsIndex;