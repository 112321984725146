import { makeAutoObservable, runInAction } from "mobx";

export var Names  = {
    DataChangeApprover: "Data Change Approvers",
	OfficeClosedFrom: "Office Closed From",
	OfficeClosedTo: "Office Closed To"
}

export var Groups = {
    Approvals: "Approvals",
	Admin: "Admin"
}

/**
 * @typedef SettingsStore
 * @type { SettingsStore }
 */
 export default class SettingsStore {
     UpdatingSettings = false;
     GettingSettings = false;

     /**@type { Array<import("../Services/SettingsService").Setting> } */
     AvailableSettings = [];

     constructor(settingsService, toastStore) {
        /**@type { import("../Services/SettingsService").SettingsService } */
        this.SettingsService = settingsService;

         /**@type { import("../Stores/ToastStore").ToastStore } */
        this.ToastStore = toastStore;

        makeAutoObservable(this);
    }

    async GetAvailableSettings() {
        this.GettingSettings = true;

        var result = await this.SettingsService.GetSettings();
        runInAction(() => this.GettingSettings = false);

        if(result) {
            if(result.Success) {
                runInAction(() => this.AvailableSettings = result.Data);
            } else {
                this.ToastStore.ShowErrorToast("Failed to retrieve available settings: " + result.Message);
            }
        }
    }

    async UpdateSettings() {
        this.UpdatingSettings = true;

        var result = await this.SettingsService.UpdateSetiings(this.AvailableSettings);

        runInAction(() => this.UpdatingSettings = false);

        if(result) {
            if(result.Success) {
                this.ToastStore.ShowSuccessToast("Settings updated successfully.");
            } else {
                this.ToastStore.ShowErrorToast("Failed to update settings: " + result.Message);
            }
        }
    }

    SetSetting(name, group, category, subCategory, value) {
        var setting = this.GetSetting(name, group, category, subCategory);

        if(setting) {
            runInAction(() => setting.Value = value);
        }
    }

    /**
     * Returns the setting based on the provided filters or null of no setting could be found
     */
    GetSetting(name, group, category, subCategory) {
        var settings = this.AvailableSettings;

        if(subCategory) {
            settings = settings.filter((value) => value.Subcategory === subCategory);
        }

        if(category) {
            settings = settings.filter((value) => value.Category === category);
        }

        if(group) {
            settings = settings.filter((value) => value.Group === group);
        }

        var setting = settings.find(value => value.Name === name);

        return setting;
    }
}