import APIBase from "./APIBase";

/**
 * @typedef NotificationVM
 * @property {string} Subject
 * @property {string} DateCreated
 * @property {string} CreatedByUser
 * @property {number} TotalMembersSent
 * @property {number} TotalMembersRead
 * @property {number} NotificationType
 */

/**
 * @typedef Notification
 * @property {number} NotificationType
 * @property {string} Subject
 * @property {string} Body
 * @property {number} Filter
 */

/**
 * @typedef NotificationsService
 * @type {NotificationsService}
 */
export default class NotificationsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Notifications", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<NotificationVM> }
	 */
	async GetNotifications() {
		return this.Get(`GetNotifications`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async CreateNotification(notification) {
		return this.Post(`CreateNotification`, notification);
	}
}