import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { Roles } from "../../Stores/UserStore";
import Sidebar from "../Core/Sidebar";
import Toastbar from "../Core/Toastbar";
import Topbar from "../Core/Topbar";
import AdhocReports from "./AdhocReports/AdhocReports";
import ApprovalsIndex from "./Approvals/ApprovalsIndex";
import BatchesIndex from "./Batches/BatchesIndex";
import CATSTransfersIndex from "./CATSTransfers/CATSTransfersIndex";
import ControlsIndex from "./Controls/ControlsIndex";
import DailyWithdrawalsIndex from "./DailyWithdrawals/DailyWithdrawalsIndex";
import DeathBenefitFundIndex from "./DeathBenefitFund/DeathBenefitFundIndex";
import { DepartmentsIndex } from "./Departments/DepartmentsIndex";
import DepositsImportIndex from "./DepositsImport/DepositsImportIndex";
import MembersIndex from "./Members/MembersIndex";
import PeriodEndIndex from "./PeriodEnd/PeriodEndIndex";
import Settings from "./Settings/Settings";
import UsersIndex from "./Users/UsersIndex";
import NotificationsIndex from "./Notifications/NotificationsIndex";

export var Main = withRouter(function Main() {
    const store = useSessionStore();

    return <div className="h-100">
        <Topbar></Topbar>
        <Sidebar></Sidebar>
        <div style={{ marginLeft: "260px" }} className="pt-3">
            <Switch>
				<Route path="/" exact>
                    <Redirect to="/Members"></Redirect>
                </Route>
                <Route path="/Members" component={ MembersIndex }></Route>
                <Route path="/DailyWithdrawals" component={ DailyWithdrawalsIndex }></Route>
				<Route path="/CATSTransfers" component={ CATSTransfersIndex }></Route>
				<Route path="/DepositsImport" component={ DepositsImportIndex }></Route>
                <Route path="/Batches" component={ BatchesIndex }></Route>
				<Route path="/PeriodEnd" component={ PeriodEndIndex }></Route>
				<Route path="/AdhocReports" component={ AdhocReports }></Route>
				<Route path="/DeathBenefitFund" component={ DeathBenefitFundIndex }></Route>
				<Route path="/Notifications" component={ NotificationsIndex }></Route>
                {
                    store.Session.User.RoleId === Roles.Admin &&
                    <div>
                        <Route path="/Approvals" component={ ApprovalsIndex }></Route>
                        <Route path="/Controls" component={ ControlsIndex }></Route>
                        <Route path="/Departments" component={ DepartmentsIndex }></Route>
                        <Route path="/Users" component={ UsersIndex }></Route>
                        <Route path="/Settings" component={ Settings }></Route>
                    </div>
                }

            </Switch>
        </div>
        <Toastbar></Toastbar>
    </div>
});

export default Main;