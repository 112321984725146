import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import BusyIndicator from "../../Core/BusyIndicator";
import * as yup from 'yup';
import { Formik, Form as FormikForm } from "formik";
import { Form } from "react-bootstrap-formik";
import { Roles } from "../../../Stores/UserStore";

export var UserDetails = observer(function Details(props) {
    var store = useSessionStore();
    const [user, setUser] = useState({ Id: props.userId, FirstName: "", LastName: "", Email: "", Password: "", ConfirmPassword: "", PhoneNumber: "", RoleId: 2 });

    useEffect(() => {
        async function getUser() {
            var user = await store.UsersStore.CRUD.GetEntry(props.userId);
    
            if(user) {
                setUser(user)

            } else {
                props.onEditComplete(false)
            }
        }

        if(props.userId) {
            getUser();
        }
    }, [props, store.UsersStore]);

    async function handleSubmit (data) { 
        var result = false;
        
        if(user.Id) {
            result = await store.UsersStore.CRUD.UpdateEntry(data);
        } else {
            result = await store.UsersStore.CRUD.CreateEntry(data);
        }

        if(result) {
            props.onEditComplete(true);
        }
    }

    const validationSchema = 
        yup.object({ 
            Id: yup.mixed().notRequired(),
            RoleId: yup.number().required("Role is required"),
            FirstName: yup.string().required("First Name is required."), 
            LastName: yup.string().required("Last Name is required."),
            Email: yup.string().email("Valid Email is required.").required("Email Address is required."),
            PhoneNumber: yup.string().matches(/[0-9]$/, 'Phone number must contain only numbers').length(10, 'Phone number must be 10 digits').required("Phone number is required."),
            Password: yup.mixed().when("Id", { is: (Id) => Id === null || Id === undefined, then: yup.mixed().required("Password is required") }),
            ConfirmPassword: yup.mixed().when("Id", { is: (Id) => Id === null || Id === undefined, then: yup.mixed().required("Confirm Password is required") }) }
        ).required();

    return  <div>
                {
                    store.UsersStore.CRUD.GettingEntry === false &&
                    <Formik validationSchema={ validationSchema } enableReinitialize={true} initialValues={user} onSubmit={ handleSubmit }>
                        <FormikForm>
                            <Form.Input label="First Name" name="FirstName" />
                            <Form.Input label="Last Name" name="LastName" />
                            <Form.Input label="Email Address" name="Email" />
                            <Form.Input label="Phone Number" name="PhoneNumber" />
                            <hr></hr>
                            <Form.Select label="Role" name="RoleId">
                                <option value={ Roles.Admin }>Admin</option>
                                <option value={ Roles.Operator }>Operator</option>
                            </Form.Select>
                            <hr></hr>
                            <Form.Input type="password" label="Password" name="Password" />
                            <Form.Input type="password" label="Confirm Password" name="ConfirmPassword" />
                            <Row className="modal-footer pb-0">
                                <Button disabled={ store.UsersStore.CRUD.UpdatingEntry } type="submit">{ store.UsersStore.CRUD.UpdatingEntry ? "Saving..." : "Save" }</Button>
                                <Button onClick={ e => props.onEditComplete(false) } variant="danger" disabled={ store.UsersStore.CRUD.UpdatingEntry } type="button">Cancel</Button>
                            </Row>  
                        </FormikForm>
                    </Formik>
                }
                {
                    store.UsersStore.CRUD.GettingEntry  &&
                    <div className="text-center">
                        <BusyIndicator show={ store.UsersStore.CRUD.GettingEntry }></BusyIndicator>
                        <h5 className="mt-3">Retrieving User...</h5>
                    </div>
                }
            </div>
});

export default UserDetails