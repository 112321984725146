import APIBase from "./APIBase";

/**
 * @typedef WithdrawalCounts
 * @property { number } TotalPendingWithdrawals
 * @property { number } TotalWithdrawalsThisMonth
 * @property { number } TotalWithdrawalsThisYear
 */

/**
 * @typedef PendingTransaction
 * @property { number } TransactionNumber
 * @property { string } TransactionDate
 * @property { number } Amount
 * @property { number } TransactionTypeId
 * @property { string } TransactionId
 * @property { string } MemberDetails
 * @property { string } Reference
 */

/**
 * @typedef NewWithdrawalState
 * @property { string } CurrentDate
 * @property { number } MaxAmount
 * @property { Array<PendingTransaction> } PendingWithdrawals
 */

/**
 * @typedef MemberWithdrawalsService
 * @type {MemberWithdrawalsService}
 */
export default class MemberWithdrawalsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     * */
     constructor(apiUrl, sessionStore) {
        super(apiUrl + "MemberWithdrawals", sessionStore);
    }

    /**
     * @param {string} memberId The Id of the member
     * @returns { Promise<import("./APIBase").RequestResult<NewWithdrawalState>> }
     */
     async GetStateForNewMemberWithdrawal(memberId) {
        return this.Get(`GetStateForNewMemberWithdrawal?memberId=${memberId}`);
    }

    /**
     * @param {string} memberId The Id of the member
     * @returns { Promise<import("./APIBase").RequestResult<WithdrawalCounts>> }
     */
     async GetWithdrawalCountsForMember(memberId) {
        return this.Get(`GetWithdrawalCountsForMember?memberId=${memberId}`);
    }

    /**
     * @param {Number} amount The total amount of the withdrawal
     * @param {string} withdrawalDate The date of the withdrawal
     * @param {String} memberId The Id of the member
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async CreateWithdrawalForMember(amount, withdrawalDate, memberId) {
        return this.Post(`CreateWithdrawalForMember?amount=${amount}&withdrawalDate=${withdrawalDate}&memberId=${memberId}`, null);
    }

    /**
     * @returns { Promise<import("./APIBase").EnumerableRequestResult<PendingTransaction>> }
     */
     async GetUnbatchedWithdrawals(sortColumn, sortDirection, transactionRef) {
        return this.Get(`GetUnbatchedWithdrawals?sortColumn=${sortColumn}&sortDirection=${sortDirection}&transactionRef=${transactionRef}`);
    }

    /**
     * @param {String} transactionId The Id of the withdrawal to delete
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async DeleteUnbatchedWithdrawal(transactionId) {
        return this.Post(`DeleteUnbatchedWithdrawal?transactionId=${transactionId}`, null);
    }

    /**
     * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
     */
     async PostDailyWithdrawals() {
        return this.Post(`PostDailyWithdrawals`, null);
    }
}