export const MemberUserNotificationTypes = {
	All: 0, 
	PensionersWithNoAddress: 1, 
	FICANonCompliant: 2, 
	BalanceMoreThanSpecifiedNotTerminated: 3
}

export function getMemberUserNotificationType(type) {
	switch(type) {
		case MemberUserNotificationTypes.All:
			return "All Members";
		case MemberUserNotificationTypes.BalanceMoreThanSpecifiedNotTerminated:
			return "Members With Balance More Than Specified";
		case MemberUserNotificationTypes.FICANonCompliant:
			return "FICA Non Compliant Members";
		case MemberUserNotificationTypes.PensionersWithNoAddress:
			return "Pensioners With No Address";
		default:
			return "Unknown";
	}
}