import { Button, Col, Row, Table } from "react-bootstrap";
import Panel from "../../Core/Panel";
import PanelHeader from "../../Core/PanelHeader";
import { faComment, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusyIndicator from "../../Core/BusyIndicator";
import Modal from "../../Core/Modal";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { getMemberUserNotificationType } from "./Enums";
import CreateNotification from "./CreateNotification";

export default function NotificationsIndex() {
	const store = useSessionStore();

	const [notifications, setNotifications] = useState(null);
	const [showCreateNotificationModal, setShowCreateNotificationModal] = useState(false);

	async function getNotifications() {
		setNotifications(null);

		const result = await store.NotificationsService.GetNotifications();

		if(result && result.Success) {
			setNotifications(result.Data);
		}
	}

	function handleNotificationsModalClosed(update) {
		if(update) {
			getNotifications();
		}

		setShowCreateNotificationModal(false);
	}

	useEffect(() => {
		getNotifications();
	}, []);

	return <Panel>
			<PanelHeader icon={ faComment } title="Notifications">
				<Button variant="outline-primary" onClick={ () => setShowCreateNotificationModal(true) }><FontAwesomeIcon icon={ faPlus }></FontAwesomeIcon> Create Notification</Button>
			</PanelHeader>
			<Row>
				<Col lg={12}>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Date Created</th>
								<th>Type</th>
								<th>Subject</th>
								<th>Created By</th>
								<th>Sent/Opened</th>
							</tr>
						</thead>
						{
							notifications &&
							<tbody>
							{
								notifications.length > 0 &&
								notifications.map((n, index) =>
								<tr key={ index }>
									<td>{ DateTime.fromISO(n.DateCreated).toFormat("yyyy-MM-dd") }</td>
									<td>{ getMemberUserNotificationType(n.NotificationType) }</td>
									<td>{ n.Subject }</td>
									<td>{ n.CreatedByUser }</td>
									<td>{ n.TotalMembersSent } / {  n.TotalMembersRead }</td>
								</tr>
								)
							}

							{
								notifications.length === 0 &&
								<tr className="text-center">
									<td colSpan="5"><b className="text-muted">No notifications returned</b></td>
								</tr>
							}
							</tbody>
						}
					</Table>
					<BusyIndicator text="Retrieving Notifications..." show={ notifications === null }></BusyIndicator>
				</Col>
			</Row>
			<Modal size="lg" show={ showCreateNotificationModal } closeButton title="Create Notification" icon={ faComment } onHide={ () => handleNotificationsModalClosed(false)}>
				<CreateNotification onClose={ e => handleNotificationsModalClosed(e)} />
			</Modal>
		</Panel>
}