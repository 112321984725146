import { observer } from "mobx-react-lite";
import { Form } from "react-bootstrap";

var SearchBox = observer(function SearchBox(props) {
    function handleSearchBarKeyDown (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            props.action();
        }
    }

    return <Form.Control onKeyDown={ handleSearchBarKeyDown } placeholder={ props.placeholder } value={ props.value } onChange={ props.onChange } />
});

export default SearchBox;